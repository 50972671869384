import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import {
  getClientLocationAssignmentFormTemplate,
  getUserFormTemplate,
} from "./ClientLocationForm";

import CustomModal from "../../../react_utils/modals/CustomModal";
import SaveModalFooter from "../../../react_utils/modals/SaveModalFooter";

import { API_URL_ASSIGNCLIENT, API_URL_CUSTOMER } from "../../../urls";

import CustomForm, {
  getEmptyFieldsErrorFromTemplate,
} from "../../../react_utils/form/CustomForm";

import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import Checkbox from "../../../react_utils/fields/Checkbox";
import { getUserName } from "../../../react_utils/utils";

export const emptyLocationAssignmentForm = {
  client_id: null,
  location_id: null,
  street_and_number: "",
  zip_and_city: "",
  name: "",
};

export const emptyUserForm = {
  username: "",
  first_name: "",
  last_name: "",
  email: null,
};

export default function ClientLocationFormModal({
  isOpen,
  setIsOpen,
  client,
  resetParent,
  location = null,
  session,
}) {
  const [locationAssignmentForm, setLocationAssignmentForm] = useState({
    ...emptyLocationAssignmentForm,
  });
  const [createUser, setCreateUser] = useState(false);
  const [userForm, setUserForm] = useState({ ...emptyUserForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  useEffect(() => {
    setUserForm((f) => ({
      ...f,
      username: getUserName(f.first_name, f.last_name),
    }));
  }, [userForm.first_name, userForm.last_name]);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setLocationAssignmentForm({ ...emptyLocationAssignmentForm });
    setUserForm({ ...emptyUserForm });
    setCreateUser(false);
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    setLocationAssignmentForm({
      ...emptyLocationAssignmentForm,
      client_id: client.id,
      location_id: location?.id || null,
      street_and_number: location?.street_and_number || "",
      zip_and_city: location?.zip_and_city || "",
      name: location?.name || "",
    });
  };

  const submit = async (assignmentForm, userForm) => {
    return session.backendClient
      .post(API_URL_ASSIGNCLIENT, assignmentForm)
      .then((locationRes) => {
        if (!userForm) return Promise.resolve();
        userForm = { ...userForm, location: locationRes.data.id };
        return session.backendClient.post(API_URL_CUSTOMER, userForm);
      });
  };

  const onSubmit = async (onSuccess) => {
    const emptyLocationfieldsError = getEmptyFieldsErrorFromTemplate(
      locationAssignmentTemplate,
      locationAssignmentForm,
      emptyLocationAssignmentForm
    );
    const emptyUserfieldsError = createUser
      ? getEmptyFieldsErrorFromTemplate(userTemplate, userForm, emptyUserForm)
      : null;
    if (emptyLocationfieldsError || emptyUserfieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
        showMissingFields: true,
      });
      console.error(emptyLocationfieldsError || emptyUserfieldsError);
      return;
    }

    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });

    return submit(locationAssignmentForm, createUser ? userForm : null)
      .then((res) => {
        setSnackbar((s) => ({
          ...s,
          open: true,
          severity: "success",
          msg: "Das Gerät wurde erfolgreich zugeordnet",
        }));
        resetParent();
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "client:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_client_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        saveBtnLabel={"Zuordnen"}
      />
    );
  };

  const userTemplate = getUserFormTemplate();
  const locationAssignmentTemplate = getClientLocationAssignmentFormTemplate(
    setLocationAssignmentForm,
    location
  );

  return (
    <>
      <CustomModal
        title="Gerät zuordnen"
        getFooter={getFooter}
        onToggle={onToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <CustomForm
          template={locationAssignmentTemplate}
          form={locationAssignmentForm}
          setForm={setLocationAssignmentForm}
          defaultForm={emptyLocationAssignmentForm}
          showMissingFields={loadingElements.showMissingFields}
          session={session}
        />
        <Checkbox
          label={"Kundenkonto erstellen"}
          onChange={(checked) => setCreateUser(checked)}
          checked={createUser}
        />
        {createUser && (
          <CustomForm
            template={userTemplate}
            form={userForm}
            setForm={setUserForm}
            defaultForm={emptyUserForm}
            showMissingFields={loadingElements.showMissingFields}
            session={session}
          />
        )}
      </CustomModal>
      <CustomSnackbar
        message={snackbar.msg}
        duration={3000}
        severity={snackbar.severity}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </>
  );
}

ClientLocationFormModal.propTypes = {
  client: PropTypes.object,
  resetParent: PropTypes.func,
  location: PropTypes.object,
  session: PropTypes.object,
};
