import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";

import { getCustomerFormTemplate } from "./CustomerForm";

import CustomModal from "../../../react_utils/modals/CustomModal";
import SaveModalFooter from "../../../react_utils/modals/SaveModalFooter";

import {
  API_URL_CUSTOMER,
  API_URL_FORGOT_PASSWORD,
  API_URL_LOCATION,
} from "../../../urls";

import { CustomButton } from "../../../react_utils/StyledElements";
import CustomForm, {
  getEmptyFieldsErrorFromTemplate,
} from "../../../react_utils/form/CustomForm";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import { getUserName } from "../../../react_utils/utils";

export const emptyCustomerForm = {
  id: null,
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  location: "",
};

export default function CustomerFormModal({
  customer,
  location = null,
  isOpen,
  setIsOpen,
  resetParent,
  getOpenButton,
  session,
}) {
  const [customerForm, setCustomerForm] = useState({ ...emptyCustomerForm });
  const [locations, setLocations] = useState(null);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });

  const customerRef = useRef(null);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setCustomerForm(emptyCustomerForm);
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    customerRef.current = null;
    setCustomerForm({
      ...(customer || emptyCustomerForm),
      ...(location ? { location: location.id } : {}),
    });
    if (!location)
      session.backendClient
        .get(API_URL_LOCATION)
        .then((res) => setLocations(res.data));
    else setLocations([location]);
  };

  useEffect(() => {
    loadData();
  }, [customer]);

  useEffect(() => {
    if (!customer) loadData();
  }, [session.user]);

  useEffect(() => {
    if (!customer)
      setCustomerForm((f) => ({
        ...f,
        username: getUserName(f.first_name, f.last_name),
      }));
  }, [customerForm.first_name, customerForm.last_name]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton === null) return null;
    if (getOpenButton) return getOpenButton(toggle);
    if (customer) {
      return (
        <CustomButton onClick={toggle} icon="info">
          Nutzer
        </CustomButton>
      );
    }
    return (
      <CustomButton
        onClick={toggle}
        style={{ maxWidth: "300px" }}
        icon="addcustomer"
        id="add_customer_btn"
      >
        Nutzer hinzufügen
      </CustomButton>
    );
  };

  const submit = async (customerForm) => {
    return customer || customerRef.current
      ? session.backendClient.put(
          API_URL_CUSTOMER + customer?.id || customerRef.current.id,
          customerForm
        )
      : session.backendClient
          .post(API_URL_CUSTOMER, customerForm)
          .then((res) => {
            customerRef.current = res.data;
            return res;
          });
  };

  const onSubmit = async (onSuccess) => {
    const emptyFieldsError = getEmptyFieldsErrorFromTemplate(
      template,
      customerForm,
      emptyCustomerForm
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
        showMissingFields: true,
      });
      console.error(emptyFieldsError);
      return;
    }

    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });

    return submit(customerForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setSnackbar((s) => ({
          ...s,
          msg:
            customer || customerRef.current
              ? "Nutzerinfo gespeichert."
              : "Nutzer erfolgreich erstellt.",
          open: true,
        }));
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError:
            error?.response?.status === 409
              ? "Dieser Nutzername ist bereits vergeben"
              : true,
          inProgress: false,
        });
      });
  };

  const onDelete = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });

    return session.backendClient
      .delete(API_URL_CUSTOMER + customer.id)
      .then((res) => {
        resetParent();
        onSuccess();
        setSnackbar((s) => ({
          ...s,
          msg: "Nutzer erfolgreich gelöscht.",
          open: true,
        }));
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onDelete"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_customer_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onDelete={customer ? () => onDelete(toggle) : null}
        saveBtnLabel={!!customer ? "Speichern" : "Erstellen"}
      />
    );
  };

  const sendAccessData = () => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });

    return session.backendClient
      .post(API_URL_FORGOT_PASSWORD, { username_or_email: customer.username })
      .then((res) => {
        setSnackbar((s) => ({
          ...s,
          msg: "Nutzerdaten erneut gesendet.",
          open: true,
        }));
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "customer:sendAccessData"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const template = getCustomerFormTemplate(setCustomerForm, locations, session);

  return (
    <>
      <CustomModal
        getOpenButton={_getOpenButton}
        title="Nutzerinformationen"
        getFooter={getFooter}
        onToggle={onToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <CustomForm
          template={template}
          form={customerForm}
          setForm={setCustomerForm}
          defaultForm={emptyCustomerForm}
          showMissingFields={loadingElements.showMissingFields}
          session={session}
        />
        {customer && (
          <CustomButton onClick={sendAccessData}>
            Neue Zugangsdaten senden
          </CustomButton>
        )}
      </CustomModal>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </>
  );
}

CustomerFormModal.propTypes = {
  customer: PropTypes.object,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
};
