import React from "react";
import "reactflow/dist/style.css";
import { PropTypes } from "prop-types";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, DesktopDateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/de";
import { popFromObj } from "./utils";

export default function DatePicker({
  date,
  setDate,
  disableFuture = true,
  disabledPast = false,
  minDate,
  maxDate,
  time = false,
  label = "Datum",
  popperProps = {},
  ...props
}) {
  const propsSx = popFromObj(props, "sx") || {};

  const datepickerProps = {
    disableFuture: disableFuture,
    disabledPast: disabledPast,
    label: label,
    value: dayjs(date),
    minDate: minDate && dayjs(minDate),
    maxDate: maxDate && dayjs(maxDate),
    onChange: (v) => {
      setDate(v.toDate());
    },

    slotProps: {
      textField: {
        variant: "standard",
        InputProps: {
          sx: {
            // color: "#efefef",
            "&:before": { borderBottomColor: "#efefef" }, // Default line color
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#efefef", // Hover color
            },
            "&:after": { borderBottomColor: "#efefef" }, // Focused color
            "& .MuiPickersDay": { backgroundcolor: "green" },
          },
        },
      },
      popper: {
        //disablePortal: true,
        strategy: "fixed",
        ...popperProps,
        sx: {
          ".MuiPaper-root": {
            color: "white",
            backgroundColor: "tertiary.main",
          },
          ".MuiPickersYear-root": { color: "white" },
          ".MuiPickersYear-root .Mui-selected": {
            color: "white",
            backgroundColor: "#232323",
          },
          ".MuiPickersYear-root .Mui-disabled": { color: "#adadad" },
          ".MuiPickersMonth-root": { color: "white" },
          ".MuiPickersMonth-root .Mui-selected": {
            color: "white",
            backgroundColor: "#232323",
          },
          ".MuiPickersMonth-root .Mui-disabled": { color: "#adadad" },
          ".MuiPickersCalendarHeader-switchViewButton": { color: "white" },
          ".MuiPickersArrowSwitcher-button": { color: "white" },
          ...(popperProps?.sx || {}),
        },
      },
    },
    sx: {
      "& .MuiInputLabel-root.Mui-focused": { color: "#efefef" }, //styles the label
      "& .MuiInputLabel-root": { color: "#efefef" },
      "& .MuiSvgIcon-root": { color: "#efefef" },
      ...propsSx,
    },
    ...props,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      {time ? (
        <DesktopDateTimePicker {...datepickerProps} />
      ) : (
        <DesktopDatePicker {...datepickerProps} />
      )}
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  session: PropTypes.object,
};
