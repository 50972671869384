import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Grid } from "@mui/material";

import EnergyFlow from "../flow/EnergyFlow";

import {
  API_URL_FLOW,
  API_URL_HEATPUMPSETPOINT,
  API_URL_WALLBOX,
  API_URL_WALLBOXSETPOINT,
  API_URL_WALLBOXLOCKTIME,
  API_URL_HEATPUMP,
} from "../../../urls";
import HeatpumpCard from "../heatpump/HeatpumpCard";
import WallboxCard from "../wallbox/WallboxCard";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import { datetime2String, time2String } from "../../../react_utils/utils";

export default function FlowTab({ location, session }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });
  const [flowData, setFlowData] = useState(null);
  const [wallboxes, setWallboxes] = useState(null);
  const [heatpumps, setHeatpumps] = useState(null);

  const resetState = () => {
    loadData();
  };

  const updateFlow = async () => {
    if (!location) return;
    const params = {
      location: location.id,
    };
    session.backendClient.get(API_URL_FLOW, { params }).then((res) => {
      setFlowData(res.data);
    });
  };

  const updateWallboxes = () => {
    if (!location) return location;
    session.backendClient
      .get(API_URL_WALLBOX, {
        params: { nested: true, location: location.id },
      })
      .then((res) => {
        setWallboxes(res.data);
      });
  };

  const updateHeatpumps = () => {
    if (!location) return location;
    session.backendClient
      .get(API_URL_HEATPUMP, {
        params: { nested: true, location: location.id },
      })
      .then((res) => {
        setHeatpumps(res.data);
      });
  };

  const loadData = () => {
    updateFlow();
    updateWallboxes();
    updateHeatpumps();
  };

  useEffect(() => {
    loadData();
    const interval = setInterval(() => {
      loadData();
    }, 5000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [location]);

  const editWallboxName = (wallbox, name) => {
    return session.backendClient.put(API_URL_WALLBOX + wallbox.id, { name });
  };

  const editHeatpumpName = (heatpump, name) => {
    return session.backendClient.put(API_URL_HEATPUMP + heatpump.id, { name });
  };

  const editHeatpumpTime = (heatpump, lower, higher) => {
    return session.backendClient.put(API_URL_HEATPUMP + heatpump.id, {
      sg_ready_start_threshold: time2String(lower),
      sg_ready_end_threshold: time2String(higher),
    });
  };

  const _updateWallboxSetPoint = (setPoint) => {
    setWallboxes((ws) =>
      ws.map((w) => {
        if (w._set_point && w._set_point.id === setPoint.id)
          w._set_point = { ...w._set_point, ...setPoint };
        return w;
      })
    );
  };

  const _updateHeatpumpSetPoint = (setPoint) => {
    setHeatpumps((hs) =>
      hs.map((h) => {
        if (h._set_point && h._set_point.id === setPoint.id)
          h._set_point = { ...h._set_point, ...setPoint };
        return h;
      })
    );
  };

  const handleWallboxLockChange = (setpoint, locked) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_WALLBOXSETPOINT + setpoint.id,
      { locked: locked }
    );
    return promise
      .then((res) => {
        _updateWallboxSetPoint(res.data);
      })
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "Die Wallbox wurde erfolgreich " +
            (locked ? "gesperrt!" : "enstperrt!"),
          open: true,
        }))
      );
  };

  const handleWallboxSurplusChange = (setpoint, surplus_charging) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_WALLBOXSETPOINT + setpoint.id,
      { surplus_charging: surplus_charging }
    );
    return promise
      .then((res) => _updateWallboxSetPoint(res.data))
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "PV-Überschussladen wurde erfolgreich " +
            (surplus_charging ? "aktiviert!" : "deaktiviert!"),
          open: true,
        }))
      );
  };

  const handleWallboxHybridChange = (setpoint, hybrid_charging_current) => {
    const showSnackbar =
      !!setpoint.hybrid_charging_current !== !!hybrid_charging_current;
    if (showSnackbar) setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_WALLBOXSETPOINT + setpoint.id,
      { hybrid_charging_current }
    );
    return promise
      .then((res) => _updateWallboxSetPoint(res.data))
      .then(() => {
        if (showSnackbar)
          setSnackbar((s) => ({
            ...s,
            msg:
              "PV-Hybridladen wurde erfolgreich " +
              (hybrid_charging_current ? "aktiviert!" : "deaktiviert!"),
            open: true,
          }));
      });
  };

  const addWallboxLockTime = (wallbox, locked, datetime) => {
    return session.backendClient
      .post(API_URL_WALLBOXLOCKTIME, {
        wallbox: wallbox.id,
        locked,
        datetime: datetime2String(datetime),
      })
      .then((res) => {
        setWallboxes((ws) =>
          ws.map((w) =>
            w.id === wallbox.id
              ? {
                  ...w,
                  _lock_times: [...w._lock_times, res.data],
                }
              : w
          )
        );
      })
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg: `${
            locked ? "Sperrzeit" : "Entsperrzeit"
          } erfolgreich hinzugefügt.`,
          open: true,
        }))
      );
  };

  const deleteWallboxLockTime = (wallbox, lockTime) => {
    return session.backendClient
      .delete(API_URL_WALLBOXLOCKTIME + lockTime.id)
      .then((res) => {
        setWallboxes((ws) =>
          ws.map((w) =>
            w.id === wallbox.id
              ? {
                  ...w,
                  _lock_times: w._lock_times.filter(
                    (s) => s.id !== lockTime.id
                  ),
                }
              : w
          )
        );
      })
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg: `${
            lockTime.locked ? "Sperrzeit" : "Entsperrzeit"
          } erfolgreich gelöscht.`,
          open: true,
        }))
      );
  };

  const handleHeatpumpSgReadyChange = (setpoint, sg_ready) => {
    setSnackbar((s) => ({ ...s, open: false }));
    const promise = session.backendClient.put(
      API_URL_HEATPUMPSETPOINT + setpoint.id,
      { sg_ready: sg_ready }
    );
    return promise
      .then((res) => {
        _updateHeatpumpSetPoint(res.data);
      })
      .then(() =>
        setSnackbar((s) => ({
          ...s,
          msg:
            "PV-Einschaltbefehl wurde erfolgreich " +
            (sg_ready ? "aktiviert!" : "deaktiviert!"),
          open: true,
        }))
      );
  };

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid
          xs={12}
          sm={12}
          md={12}
          item
          display={"flex"}
          justifyContent="center"
          alignItems="center"
        >
          {flowData && (
            <EnergyFlow
              flowheight={{ xs: "400px", md: "600px" }}
              flowData={flowData}
            />
          )}
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          {(heatpumps || []).map((heatpump) => (
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={4}
              item
              justifyContent="center"
              alignItems="center"
              key={`heatpump-card-wrapper-${heatpump.id}`}
            >
              <HeatpumpCard
                heatpump={heatpump}
                editHeatpumpTime={
                  session.user.is_superuser ? editHeatpumpTime : null
                }
                editHeatpumpName={
                  session.user.is_superuser && heatpumps && heatpumps.length > 1
                    ? editHeatpumpName
                    : null
                }
                setHeatpumpSgReady={
                  session.user.is_superuser && handleHeatpumpSgReadyChange
                }
                resetParent={resetState}
                infoText={false}
                session={session}
              />
            </Grid>
          ))}
          {(wallboxes || []).map((wallbox) => (
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={4}
              item
              justifyContent="center"
              alignItems="center"
              key={`wallbox-card-wrapper-${wallbox.id}`}
            >
              <WallboxCard
                wallbox={wallbox}
                editWallboxName={
                  session.user.is_superuser && wallboxes && wallboxes.length > 1
                    ? editWallboxName
                    : null
                }
                setWallboxLocked={
                  session.user.is_superuser && handleWallboxLockChange
                }
                setWallboxSurplusCharging={
                  session.user.is_superuser && handleWallboxSurplusChange
                }
                setWallboxHybridCharging={
                  session.user.is_superuser && handleWallboxHybridChange
                }
                addWallboxLockTime={addWallboxLockTime}
                deleteWallboxLockTime={deleteWallboxLockTime}
                infoText={false}
                resetParent={resetState}
                session={session}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

FlowTab.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object,
};
