import React from "react";
import { PropTypes } from "prop-types";

import GoogleIcon from "@mui/icons-material/Google";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import SendIcon from "@mui/icons-material/Send";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DownloadIcon from "@mui/icons-material/Download";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
// import SettingsIcon from '@mui/icons-material/Settings'
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import RefreshIcon from "@mui/icons-material/Refresh";
import HistoryIcon from "@mui/icons-material/History";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BarChartIcon from "@mui/icons-material/BarChart";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SolarPowerOutlinedIcon from "@mui/icons-material/SolarPowerOutlined"; // solar
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"; // mieter
import HubOutlinedIcon from "@mui/icons-material/HubOutlined"; // sammel
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined"; // allgemein
import HeatPumpOutlinedIcon from "@mui/icons-material/HeatPumpOutlined"; // wärmepumpe
import AddIcon from "@mui/icons-material/Add";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CastOutlinedIcon from "@mui/icons-material/CastOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";

export default function Icon({ icon, ...props }) {
  switch (icon) {
    case "wifi":
      return <WifiOutlinedIcon {...props} />;
    case "account":
      return <AccountCircleOutlinedIcon {...props} />;
    case "timeline":
      return <TimelineOutlinedIcon {...props} />;
    case "recording":
      return <RadioButtonCheckedOutlinedIcon {...props} />;
    case "temperature":
      return <ThermostatOutlinedIcon {...props} />;
    case "addflat":
      return <DomainAddOutlinedIcon {...props} />;
    case "add":
      return <AddIcon {...props} />;
    case "solarpower":
      return <SolarPowerOutlinedIcon {...props} />;
    case "person":
      return <PersonOutlineOutlinedIcon {...props} />;
    case "hub":
      return <HubOutlinedIcon {...props} />;
    case "bulb":
      return <EmojiObjectsOutlinedIcon {...props} />;
    case "heatpump":
      return <HeatPumpOutlinedIcon {...props} />;
    case "savings":
      return <SavingsOutlinedIcon {...props} />;
    case "usermanagement":
      return <ManageAccountsOutlinedIcon {...props} />;
    case "settings":
      return <SettingsOutlinedIcon {...props} />;
    case "addgroup":
      return <GroupAddOutlinedIcon {...props} />;
    case "addperson":
      return <PersonAddAlt1OutlinedIcon {...props} />;
    case "statistics":
      return <BarChartIcon {...props} />;
    case "invoice":
      return <ReceiptIcon {...props} />;
    case "product":
      return <Inventory2OutlinedIcon {...props} />;
    case "people":
      return <PeopleOutlineIcon {...props} />;
    case "sell":
      return <SellOutlinedIcon {...props} />;
    case "google":
      return <GoogleIcon {...props} />;
    case "stats":
      return <PollOutlinedIcon {...props} />;
    case "handshake":
      return <HandshakeOutlinedIcon {...props} />;
    case "euro":
      return <EuroOutlinedIcon {...props} />;
    case "clipboard":
      return <AssignmentOutlinedIcon {...props} />;
    case "info":
      return <InfoOutlinedIcon {...props} />;
    case "construction":
      return <ConstructionOutlinedIcon {...props} />;
    case "rotateLeft":
      return <RotateLeftIcon {...props} />;
    case "rotateRight":
      return <RotateRightIcon {...props} />;
    case "weather":
      return <WbSunnyOutlinedIcon {...props} />;
    case "filter":
      return <FilterAltOutlinedIcon {...props} />;
    case "calendar":
      return <CalendarMonthIcon {...props} />;
    case "flash":
      return <ElectricBoltIcon {...props} />;
    case "time":
      return <AccessTimeOutlinedIcon {...props} />;
    case "clear":
      return <ClearIcon {...props} />;
    case "addcustomer":
      return <PersonAddIcon {...props} />;
    case "person_filled":
    case "customer_filled":
      return <PersonIcon {...props} />;
    case "personoff":
      return <PersonOffIcon {...props} />;
    case "send":
      return <SendIcon {...props} />;
    case "mail":
      return <MailOutlineIcon {...props} />;
    case "history":
      return <HistoryIcon {...props} />;
    case "download":
      return <DownloadIcon {...props} />;
    case "refresh":
      return <RefreshIcon {...props} />;
    // case 'settings':
    //   return <SettingsIcon {...props} />
    case "electricPlug":
      return <ElectricalServicesIcon {...props} />;
    case "documentation":
      return <GradingOutlinedIcon {...props} />;
    case "openLock":
      return <LockOpenIcon {...props} />;
    case "lockClock":
      return <LockClockOutlinedIcon {...props} />;
    case "reverse":
      return <KeyboardReturnIcon {...props} />;
    case "price":
      return <PriceCheckOutlinedIcon {...props} />;
    case "bin":
      return <DeleteOutlineIcon {...props} />;
    case "thumbUp":
      return <ThumbUpOffAltOutlinedIcon {...props} />;
    case "task":
      return <TaskAltIcon {...props} />;
    case "apps":
      return <AppsIcon {...props} />;
    case "previous":
      return <NavigateBeforeIcon {...props} />;
    case "next":
      return <NavigateNextIcon {...props} />;
    case "ruler":
      return <DesignServicesOutlinedIcon {...props} />;
    case "edit":
      return <EditOutlinedIcon {...props} />;
    case "delete":
      return <HighlightOffOutlinedIcon {...props} />;
    case "charge":
      return <ElectricalServicesIcon {...props} />;
    case "tune":
      return <TuneOutlinedIcon {...props} />;
    case "save":
      return <SaveOutlinedIcon {...props} />;
    case "device":
      return <ChargingStationIcon {...props} />;
    case "location":
      return <LocationOnOutlinedIcon {...props} />;
    case "ssh":
      return <CastOutlinedIcon {...props} />;
    case "clock":
      return <AccessTimeIcon {...props} />;
    default:
      return null;
  }
}

Icon.propTypes = {
  icon: PropTypes.string,
};
