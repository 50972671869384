import React, { useState } from "react";

import { PropTypes } from "prop-types";

import {
  Typography,
  Card,
  Stack,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";

import Checkbox from "../../../react_utils/fields/Checkbox";
import InfoTooltip from "../../../react_utils/InfoTooltip";
import HeatpumpNameModal from "./HeatpumpNameModal";
import Icon from "../../../react_utils/Icon";
import HeatpumpSgReadyTimeModal from "./HeatpumpSgReadyTimeModal";

const cardWidth = 300;
const cardHeight = 150;

export default function HeatpumpCard({
  heatpump,
  setHeatpumpSgReady,
  editHeatpumpName,
  editHeatpumpTime,
  infoText = true,
  resetParent,
  session,
}) {
  const [hovered, setHovered] = useState(false);


  const getOpenButton = (toggle) => {
    return (
      <Tooltip
        title={
          <Typography sx={{ fontSize: 14 }}>
            Schaltzeiten für SG-Ready einschränken
          </Typography>
        }
        enterTouchDelay={0}
      >
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent" }}
          onClick={toggle}
        >
          <Icon icon={"clock"} color="secondary" />
        </IconButton>
      </Tooltip>
    );
  };

  const heatpumpSetPoint = heatpump._set_point
  if (!heatpumpSetPoint) return null

  return (
    <Card
      key={`heatpumpsetpoint-${heatpump.id}`}
      sx={{
        minWidth: cardWidth,
        minHeight: cardHeight,
        padding: "10px",
        backgroundColor: "tertiary.main",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Grid container justify="center">
        <Grid
          xs={6}
          sm={6}
          md={6}
          item
          style={{ display: "flex", alignItems: "center" }}
        >
          <Stack direction="row" spacing={1}>
            <Typography color="secondary" variant="h6">
              Wärmepumpe
            </Typography>
            {editHeatpumpName && (
              <HeatpumpNameModal
                heatpump={heatpump}
                editHeatpumpName={editHeatpumpName}
                modalProps={{
                  getOpenButton: (toggle) =>
                    hovered && (
                      <IconButton
                        sx={{ p: 0 }}
                        disableFocusRipple
                        disableRipple
                        style={{ backgroundColor: "transparent" }}
                        onClick={toggle}
                      >
                        <Icon
                          icon={"edit"}
                          sx={{ color: "secondary.main" }}
                          fontSize="small"
                        />
                      </IconButton>
                    ),
                }}
                resetParent={resetParent}
                session={session}
              />
            )}
          </Stack>
        </Grid>
        {editHeatpumpTime && (
          <Grid
            xs={6}
            sm={6}
            md={6}
            item
            style={{ display: "flex" }}
            justifyContent={"flex-end"}
          >
            <HeatpumpSgReadyTimeModal
              heatpump={heatpump}
              modalProps={{ getOpenButton }}
              editHeatpumpTime={editHeatpumpTime}
              resetParent={resetParent}
              session={session}
            />
          </Grid>
        )}
      </Grid>
      <Checkbox
        checked={heatpumpSetPoint.sg_ready}
        onChange={(value) => {
          setHeatpumpSgReady && setHeatpumpSgReady(heatpumpSetPoint, value);
        }}
        disabled={!setHeatpumpSgReady}
        label={
          <Typography>
            PV-Einschaltbefehl{" "}
            {infoText && (
              <InfoTooltip>
                Ihre Wärmepumpe ist SG-Ready-fähig. Dies bedeutet, dass die
                Soll-Temperatur erhöht werden kann, wenn viel Sonnenenergie
                verfügbar ist. Zur Schonung Ihrer Wärmepumpe sollte dies jedoch
                nicht beliebig oft erfolgen. Die Steuerung der Wärmepumpe
                übernimmt das System für Sie basierend auf Wetter- und
                Lastvorhersagen. Daher sehen Sie hier lediglich den Status,
                können Ihn aber nicht manuell ändern. Bitte beachten Sie, dass
                es ein paar Tage dauern kann, bis die Soll-Temperatur der
                Wärmepumpe das erste Mal bei hoher PV-Erzeugung erhöht wird, da
                zunächst ein Kalibiervorgang für die Schätzung der
                Verbrauchswerte läuft.
              </InfoTooltip>
            )}
          </Typography>
        }
      />
    </Card>
  );
}

HeatpumpCard.propTypes = {
  heatpumpSetPoint: PropTypes.object,
  setHeatpumpSgReady: PropTypes.func,
  infoText: PropTypes.bool,
  session: PropTypes.object,
};
