import React, { Fragment } from "react";

import { PropTypes } from "prop-types";

import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  CssBaseline,
  Box,
} from "@mui/material";

import MiniDrawer from "./MiniDrawer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#efefef", // white
      contrastText: "#000000", // black
    },
    secondary: {
      main: "#34a894", // turquoise
      contrastText: "#efefef", // white
    },
    tertiary: {
      main: "#323436", // gray
      contrastText: "#efefef",
    },
    disabled: {
      main: "#adadad", // gray
    },
    error: {
      //main: '#7a2b2b',
      main: "#d32f2f",
    },
    // success: {
    //   main: '#1f6638'
    // },
    background: {
      default: "#232323", // dark gray
      main: "#232323",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#ffffff", // white.
      secondary: "#ffffff", // white
      disabled: "#adadad", // light gray
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "#323436",
        },
        option: {
          backgroundColor: "#323436",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "#323436",
          "&.Mui-expanded": {
            background: "#323436",
          },
          ".MuiAccordionDetails-root": {
            backgroundColor: "#232323",
          },
          boxShadow: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        ::selection {
          background-color: #34a894; /* Highlight background color */
          color: #FFFFFF; /* Highlight text color */
        }
      `,
    },
  },
});

export default function Layout({
  session,
  pageName,
  navigation = null,
  children,
}) {
  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {navigation != null ? (
          <>
            <MiniDrawer
              session={session}
              pageName={pageName}
              navigation={navigation}
            />
            <Box
              sx={{
                paddingX: { xs: "10px", md: "100px" },
              }}
            >
              {children}
            </Box>
          </>
        ) : (
          children
        )}
      </MuiThemeProvider>
    </Fragment>
  );
}

Layout.propTypes = {
  session: PropTypes.object,
  pageName: PropTypes.string,
  children: PropTypes.node,
};
