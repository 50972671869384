import React, { useState } from "react";

import { PropTypes } from "prop-types";

import {
  Typography,
  Grid,
  Stack,
  Card,
  Tooltip,
  IconButton,
  Slider,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

import Icon from "../../../react_utils/Icon";
import Checkbox from "../../../react_utils/fields/Checkbox";
import InfoTooltip from "../../../react_utils/InfoTooltip";
import WallboxNameModal from "./WallboxNameModal";
import WallboxLockTimeModal from "./WallboxLockTimeModal";

const cardWidth = 300;
const cardHeight = 150;

export default function WallboxCard({
  wallbox,
  editWallboxName,
  setWallboxLocked,
  setWallboxSurplusCharging,
  setWallboxHybridCharging,
  addWallboxLockTime,
  deleteWallboxLockTime,
  infoText = true,
  resetParent,
  session,
}) {
  const [hovered, setHovered] = useState(false);

  const wallboxSetPoint = wallbox._set_point;
  if (!wallboxSetPoint) return null;

  return (
    <Card
      key={`wallboxsetpoint-${wallbox.id}`}
      sx={{
        minWidth: cardWidth,
        minHeight: cardHeight,
        padding: "10px",
        backgroundColor: "tertiary.main",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Grid container justify="center">
        <Grid
          xs={6}
          sm={6}
          md={6}
          item
          style={{ display: "flex", alignItems: "center" }}
        >
          <Stack direction="row" spacing={1}>
            <Typography color="secondary" variant="h6">
              {editWallboxName ? wallbox.name : "Wallbox"}
            </Typography>
            {editWallboxName && (
              <WallboxNameModal
                wallbox={wallbox}
                editWallboxName={editWallboxName}
                modalProps={{
                  getOpenButton: (toggle) =>
                    hovered && (
                      <IconButton
                        sx={{ p: 0 }}
                        disableFocusRipple
                        disableRipple
                        style={{ backgroundColor: "transparent" }}
                        onClick={toggle}
                      >
                        <Icon
                          icon={"edit"}
                          sx={{ color: "secondary.main" }}
                          fontSize="small"
                        />
                      </IconButton>
                    ),
                }}
                resetParent={resetParent}
                session={session}
              />
            )}
          </Stack>
        </Grid>
        {wallbox.lockable && (
          <Grid
            xs={6}
            item
            style={{ display: "flex" }}
            justifyContent={"flex-end"}
          >
            <Stack direction="row" spacing={1}>
              <Tooltip
                title={
                  <Typography sx={{ fontSize: 14 }}>
                    Sperrzeiten einstellen
                  </Typography>
                }
                enterTouchDelay={0}
              >
                {/* Following div is needed so that the tooltip is shown */}
                <div>
                  <WallboxLockTimeModal
                    wallbox={wallbox}
                    addWallboxLockTime={addWallboxLockTime}
                    deleteWallboxLockTime={deleteWallboxLockTime}
                    modalProps={{
                      getOpenButton: (toggle) => (
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          style={{ backgroundColor: "transparent" }}
                          onClick={toggle}
                          disabled={
                            !addWallboxLockTime || !deleteWallboxLockTime
                          }
                        >
                          <Icon icon={"lockClock"} color="secondary" />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip
                title={
                  <Typography sx={{ fontSize: 14 }}>
                    {wallboxSetPoint.locked ? "Entsperren" : "Sperren"}
                  </Typography>
                }
                enterTouchDelay={0}
              >
                {/* Following div is needed so that the tooltip is shown */}
                <div>
                  <Checkbox
                    checked={wallboxSetPoint.locked}
                    onChange={(value) => {
                      setWallboxLocked &&
                        setWallboxLocked(wallboxSetPoint, value);
                    }}
                    label=""
                    disabled={!setWallboxLocked}
                    checkBoxProps={{
                      icon: <LockOpenIcon />,
                      checkedIcon: <LockIcon />,
                    }}
                  />
                </div>
              </Tooltip>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Typography color="primary" variant="h6">
        Status: {wallboxSetPoint.locked ? "Gesperrt" : "Entsperrt"}{" "}
        {infoText && wallbox.lockable && (
          <InfoTooltip>
            Um den Status Ihrer Wallbox (Gesperrt/Entsperrt) zu ändern bzw. Ihre
            Wallbox zu (ent)sperren, klicken Sie auf das Schloss-Symbol. Sie
            können die Wallbox dauerhaft entsperrt lassen (z. B. wenn diese sich
            in Ihrer Garage befindet) oder nach dem Ladevorgang sperren, um zu
            verhindern, dass Unbefugte Ihre Wallbox nutzen.
          </InfoTooltip>
        )}
      </Typography>
      <Stack direction="column" spacing={0}>
        {wallbox.surplus_charging_compatible && (
          <Checkbox
            checked={wallboxSetPoint.surplus_charging}
            onChange={(value) => {
              setWallboxSurplusCharging &&
                setWallboxSurplusCharging(wallboxSetPoint, value);
            }}
            disabled={!setWallboxSurplusCharging}
            label={
              <Typography>
                PV-Überschussladen{" "}
                {infoText && (
                  <InfoTooltip>
                    Wenn Sie wünschen, dass Ihr E-Fahrzeug nur mit Strom aus
                    Ihrer PV-Anlage geladen wird, aktivieren Sie das
                    PV-Überschussladen. Beim PV-Überschussladen wird Ihre
                    Wallbox das E-Fahrzeug nur laden, wenn Ihre PV-Anlage mehr
                    Strom produziert als Sie verbrauchen. Dies ermöglicht eine
                    optimale Nutzung Ihrer Energie, da Sie lediglich Ihr Auto
                    laden, wenn Ihre PV-Anlage Strom produziert und so das Laden
                    mit vergleichsweise teurem Netzstrom verhindert wird. Die
                    Wallbox beginnt das Auto zu laden, sobald ihr Überschuss die
                    minimale Ladeenergie der Wallbox übersteigt. Die Ladeenergie
                    wird fortlaufend an Ihren Überschuss angepasst. Bitte
                    beachten Sie, dass ein Ladevorgang mit PV-Überschuss je nach
                    Produktion Ihrer PV-Anlage, sehr lange dauern kann.
                  </InfoTooltip>
                )}
              </Typography>
            }
          />
        )}
        {wallboxSetPoint.surplus_charging &&
          wallbox.hybrid_charging_compatible && (
            <Checkbox
              checked={wallboxSetPoint.hybrid_charging_current != null}
              onChange={(value) => {
                setWallboxHybridCharging &&
                  setWallboxHybridCharging(wallboxSetPoint, value ? 6 : null);
              }}
              disabled={!setWallboxHybridCharging}
              label={
                <Typography>
                  PV-Hybridladen{" "}
                  {infoText && (
                    <InfoTooltip>
                      Wenn Sie wünschen, dass Ihr E-Fahrzeug im hybriden Modus
                      mit der Mindestleistung aus dem Netz bzw. der Batterie und
                      zusätzlichem PV-Überschuss geladen wird, aktivieren Sie
                      das PV-Hybridladen. Die Wallbox beginnt sofort, das Auto
                      zu laden, auch wenn der PV-Überschuss die Mindestleistung
                      nicht erreicht. Die zusätzliche Ladeenergie, die nicht
                      auch dem Netz oder der Batterie kommt, wird fortlaufend an
                      Ihren Überschuss angepasst.
                    </InfoTooltip>
                  )}
                </Typography>
              }
            />
          )}
        {wallboxSetPoint.hybrid_charging_current &&
          wallbox.hybrid_charging_compatible && (
            <>
              <Typography id="input-slider">
                Ladestrom: {wallboxSetPoint.hybrid_charging_current} A{" "}
                {infoText && (
                  <InfoTooltip>
                    Hier können Sie den Ladestrom zwischen 6A und 16A
                    einstellen, mit dem mindestens geladen wird, auch wenn kein
                    Überschuss vorhanden ist.
                  </InfoTooltip>
                )}
              </Typography>
              <Slider
                aria-label="Ladestrom"
                defaultValue={6}
                getAriaValueText={(value) => `${value}`}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={6}
                max={16}
                disabled={!setWallboxHybridCharging}
                value={wallboxSetPoint.hybrid_charging_current}
                sx={{ color: "secondary.main", width: 300 }}
                onChangeCommitted={(_, value) =>
                  setWallboxHybridCharging(wallboxSetPoint, value)
                }
              />
            </>
          )}
      </Stack>
    </Card>
  );
}

WallboxCard.propTypes = {
  editWallboxName: PropTypes.func,
  setWallboxLocked: PropTypes.func,
  setWallboxSurplusCharging: PropTypes.func,
  addWallboxLockTime: PropTypes.func,
  deleteWallboxLockTime: PropTypes.func,
  resetParent: PropTypes.func,
  infoText: PropTypes.bool,
  session: PropTypes.object,
};
