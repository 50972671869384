import React, { Fragment, useState } from "react";

import { PropTypes } from "prop-types";

import CustomModal from "../../../react_utils/modals/CustomModal";
import CustomSnackbar from "../../../react_utils/CustomSnackbar";
import {
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import DropDown from "../../../react_utils/fields/DropDown";
import Icon from "../../../react_utils/Icon";
import {
  addNMinutes,
  datetime2FormattedString,
  roundToMinutes,
  string2Datetime,
} from "../../../react_utils/utils";
import DatePicker from "../../../react_utils/DatePicker";
import TimePicker from "../../../react_utils/TimePicker";

export default function WallboxLockTimeModal({
  wallbox,
  addWallboxLockTime,
  deleteWallboxLockTime,
  modalProps,
  session,
}) {
  // messaging
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "success",
  });
  const [currentLockTime, setCurrentLockTime] = useState({
    locked: false,
    datetime: null,
  });

  const onToggle = (isOpen) => {
    if (isOpen) {
      setCurrentLockTime({
        locked: false,
        datetime: roundToMinutes(addNMinutes(new Date(), 60), 15),
      });
    }
    if (modalProps.onToggle) modalProps.onToggle(isOpen);
  };

  const setDate = (d1, d2) => {
    const date = new Date(d1);
    date.setFullYear(d2.getFullYear(), d2.getMonth(), d2.getDate());
    return date;
  };

  const setTime = (d1, d2) => {
    const date = new Date(d1);
    date.setHours(d2.getHours(), d2.getMinutes(), 0, 0);
    return date;
  };

  const addCurrentTime = (locked, datetime) => {
    if (datetime < new Date()) {
      setSnackbar({
        open: true,
        msg: "Datum liegt in der Vergangenheit",
        severity: "error",
      });
      return;
    }
    addWallboxLockTime(wallbox, locked, datetime);
  };

  const lockTimes = wallbox._lock_times || [];
  const addDisabled =
    currentLockTime.locked == null || currentLockTime.datetime == null;

  return (
    <Fragment>
      <CustomModal
        size="lg"
        title={"Schaltzeiten"}
        {...modalProps}
        onToggle={onToggle}
        zIndex={7500}
      >
        {/* <CharsInput
          label="Name"
          onChange={(v) => setNewName(v)}
          value={newName}
        /> */}
        <Stack direction="column" spacing={2} marginY="20px">
          {lockTimes.length > 0 ? (
            lockTimes.map((lockTime) => (
              <Stack
                key={`lock-time-info-${lockTime.id}`}
                direction="row"
                spacing={2}
              >
                <Typography width="100px">
                  {lockTime.locked ? "    Sperren" : "Entsperren"}
                </Typography>
                <Typography>
                  {datetime2FormattedString(string2Datetime(lockTime.datetime))}
                </Typography>
                <Tooltip
                  title={<Typography sx={{ fontSize: 14 }}>Löschen</Typography>}
                >
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    style={{ backgroundColor: "transparent", padding: 0 }}
                    onClick={() => deleteWallboxLockTime(wallbox, lockTime)}
                  >
                    <Icon
                      icon={"clear"}
                      sx={{ color: "secondary.main" }}
                      fontSize="medium"
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            ))
          ) : (
            <Typography color="primary" align="center">
              Keine Zeiten zum Sperren/Entsperren für die Wallbox eingestellt.
            </Typography>
          )}
          <Divider sx={{ bgcolor: "primary.main", marginTop: 2 }} />
          <Stack direction={{ sx: "column", md: "row" }} spacing={2}>
            <DropDown
              options={[
                { value: true, label: "Sperren" },
                { value: false, label: "Entsperren" },
              ]}
              label={"Sperren/Entsperren"}
              value={currentLockTime.locked}
              error={currentLockTime.locked == null}
              onChange={(value) =>
                setCurrentLockTime((c) => ({ ...c, locked: value }))
              }
            />
            <DatePicker
              date={currentLockTime.datetime}
              setDate={(value) =>
                setCurrentLockTime((c) => ({
                  ...c,
                  datetime: setDate(c.datetime, value),
                }))
              }
              popperProps={{ sx: { zIndex: 8000 } }}
              disableFuture={false}
            />
            <TimePicker
              time={currentLockTime.datetime}
              setTime={(value) =>
                setCurrentLockTime((c) => ({
                  ...c,
                  datetime: setTime(c.datetime, value),
                }))
              }
              showClearIcon={false}
              minutesStep={15}
            />
            <IconButton
              disableFocusRipple
              disableRipple
              style={{ backgroundColor: "transparent" }}
              onClick={() =>
                addCurrentTime(currentLockTime.locked, currentLockTime.datetime)
              }
              disabled={addDisabled}
            >
              <Icon
                icon={"add"}
                sx={{ color: addDisabled ? "disabled.main" : "secondary.main" }}
                fontSize="medium"
              />
              <Typography
                sx={{ color: addDisabled ? "disabled.main" : "secondary.main" }}
                fontSize="medium"
              >
                Hinzufügen
              </Typography>
            </IconButton>
          </Stack>
        </Stack>
      </CustomModal>
      <CustomSnackbar
        severity={snackbar.severity}
        message={snackbar.msg}
        duration={3000}
        open={snackbar.open}
        setIsOpen={(isOpen) => setSnackbar((s) => ({ ...s, open: isOpen }))}
      />
    </Fragment>
  );
}

WallboxLockTimeModal.propTypes = {
  editWallboxName: PropTypes.func,
  session: PropTypes.object,
  addWallboxLockTime: PropTypes.func,
  deleteWallboxLockTime: PropTypes.func,
};
